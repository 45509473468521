<template>
    <span
        v-click-outside="closeBidsList"
        class="order-bids-list__wrapper"
        :class="applyScroll"
    >
        <span
            class="order-bids-list__close"
            @click.prevent="closeBidsList"
        >
            <close-icon size="24" />
        </span>
        <span class="order-bids-list__title">
            Other writers' bids:
        </span>
        <div
            v-for="(bid, index) in bids"
            :key="bid.owner"
            class="order-bids-list__item"
        >
            <div class="order-bids-list__index">{{ index+1 }}</div>
            <avatar
                :avatar-fallback="bid.avatar_fallback"
                :avatar-url="bid.avatar_url"
            />
            <div class="order-bids-list__main">
                <span class="order-bids-list__base-info">
                    <span>{{ bid.due_at | moment_from }}</span>
                    <span class="order-bids-list__total">${{ bid.sw_total }}</span>
                </span>
                <span>{{ bid.due_at | moment_short_text }}</span>
            </div>
        </div>
    </span>
</template>

<script>
import filtersMixin from '@/mixins/filtersMixin.js';
import Avatar from '@/components/common/Avatar.vue'
import closeIcon from 'mdi-vue/Close.vue'

export default {
    name: 'OrderCardBidList',
    components: {
        Avatar,
        closeIcon
    },
    mixins: [filtersMixin],
    props: {
        bids: {
            type: Array
        }
    },
    computed: {
        applyScroll() {
            if (this.bids.length > 7) {
                return 'tool-scroll'
            }
            return false
        }
    },
    methods: {
        closeBidsList() {
            this.$emit('closeBidsList')
        }
    }
}
</script>

<style lang="scss">
    .order-bids-list {
        &__title{
            font-size: 16px;
            font-weight: bold;
            display: block;
            text-align: center;
            padding-top: 10px;
        }
        &__wrapper {
            right: 30%;
            width: 400px;
            position: absolute;
            top: 60px;
            background: rgba(17, 97, 224, 0.99);
            color: #fff;
            z-index: 11;
            border-radius: 8px;
            padding: 5px;
            font-size: 11px;
            @media screen and (max-width:768px){
                right: 5%;
                width: 300px;
            }
        }
        &__close{
            position: absolute;
            right: -7px;
            top: -7px;
            background: rgba(17, 97, 224);
            border-radius: 50%;
            border: 2px solid white;
            cursor: pointer;
        }
        &__item{
            display: flex;
            align-items: center;
            margin: 15px 5px;
        }
        &__index{
            width: 15px;
            font-size: 16px;
            margin-right: 10px;
        }
        &__main{
            font-size: 16px;
            width: 100%;
            @media screen and (max-width:768px){
                font-size: 12px;
            }
        }
        &__base-info{
            display: flex;
            align-items: center;
            justify-content: space-between;
            line-height: 1.1;
        }
        &__total{
            font-weight: 500;
            margin-left: 10px;
            font-size: 18px;
        }
        &__item .avatar-img {
            width: 48px;
            height: 48px;
            margin-right: 10px;
        }
    }

    .tool-scroll {
        overflow-y: scroll;
        overflow-x: hidden;
        max-height: 300px !important;
    }
</style>
