<template>
    <div class="bell">
        <Bell :class="{'icon--grey': getBubbles === 0}" />
        <span
            v-if="getBubbles > 0"
            class="bell--unread tooltip-without-icon"
        >
            {{ getBubbles }}
            <span class="tool">
                <div v-if="unreadMessages > 0">{{ unreadMessages }} new messages</div>
                <div v-if="unreadTicketMessages > 0">{{ unreadTicketMessages }} support replies</div>
                <div v-if="unreadFilesAdd > 0">{{ unreadFilesAdd }} new supporting docs</div>
            </span>
        </span>
    </div>
</template>

<script>
import Bell from '@/components/icons/Bell'

export default {
    components: {
        Bell
    },
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    computed: {
        unreadMessages() {
            return this.data.counters.unread_messages_count
        },
        unreadTicketMessages() {
            return this.data.counters.unread_ticket_messages_count
        },
        unreadFilesAdd() {
            return this.data.counters.unread_files_add_count
        },
        getBubbles() {
            return this.unreadMessages + this.unreadTicketMessages + this.unreadFilesAdd
        }
    }
}
</script>

<style lang="scss">
    .bell{
        position: relative;
        .icon--grey {
            & > path {
                stroke: #9e9e9e !important;
            }
        }
        &--unread{
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: $main-color;
            color: #fff;
            position: absolute;
            right: 0;
            text-align: center;
            line-height: 20px;
            font-size: 12px;
            cursor: pointer;
        }
        &:hover {
            .tool {
                opacity: 1;
                transform: translateX(-90%);
                &:after {
                    left: 90%;
                }
                @media screen and (max-width:768px){
                    transform: translateX(-10%);
                    &:after {
                        left: 10%;
                    }
                }
            }
        }
    }
</style>
