<template>
    <div
        v-if="dialog"
        class="confirm-modal bid"
        @mousedown.self="close()"
    >
        <div class="confirm-modal__dialog">
            <header class="confirm-modal__header">
                <h2>Your Bid</h2>
                <span
                    class="confirm-modal__close"
                    @click="close()"
                />
            </header>
            <section class="confirm-modal__content bid-content">
                <div class="bid-wrapper">
                    <p class="bid-desc">
                        Amount <b>for the entire project</b> in USD:
                    </p>
                    <div class="bid-range">
                        <fieldset class="bid-range__fieldset">
                            <div class="bid-range__value--wrapper">
                                <CurrencyUsd />
                                <form-input
                                    v-model="$v.bidValue.$model"
                                    placeholder="00.00"
                                    :field.sync="bidValue"
                                    :errors="validationMessage($v.bidValue)"
                                    @input.native="$v.bidValue.$touch()"
                                    @blur.native="$v.bidValue.$touch()"
                                />
                                <!-- <span
                                    v-if="maxRate && !validationMessage($v.bidValue)"
                                    class="bid-range__warning"
                                >
                                    The rate exceeds the order amount
                                </span> -->
                            </div>
                        </fieldset>
                    </div>
                    <div class="bid-datepicker">
                        <div class="bid-deadline">
                            <span>This project is due by
                                <b :class="{ 'duedate--error' : dueInAgo }">{{ due | moment_short_text }}</b>
                            </span>
                            <span>
                                {{ due | moment_from }}
                            </span>
                        </div>
                        <p class="bid-desc">
                            Your guaranteed delivery date:
                        </p>
                        <form-date-picker
                            v-model="$v.duedate.$model"
                            label=""
                            tabindex="0"
                            :min_hours="3"
                            :field.sync="duedate"
                            :errors="validationMessage($v.duedate)"
                            @change.native="$v.duedate.$touch()"
                        />
                    </div>
                    <!--                    <div class="bid-due">-->
                    <!--                        <ClockOutline />-->
                    <!--                        {{ duedate | moment_from }}-->
                    <!--                    </div>-->
                </div>
            </section>
            <footer>
                <div class="confirm-modal__one-button-block bid-buttons">
                    <button
                        :disabled="loading"
                        class="btn-base btn-main"
                        @click.prevent="validate(false)"
                    >
                        Confirm
                    </button>
                    <button
                        :disabled="loading"
                        class="btn-base btn-secondary"
                        @click="close()"
                    >
                        Cancel
                    </button>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
import FormDatePicker from '@/components/common/form-elements/FormDatePicker.vue'
import { eventBus } from '@/helpers/event-bus'
import filtersMixin from '@/mixins/filtersMixin.js'
import moment from 'moment'

// validation
import { validationMixin } from 'vuelidate'
import formValidationMixin from '@/mixins/formValidationMixin.js'
import { validationMessage } from 'vuelidate-messages'
import { validationRules } from '@/validation/bid/Rules.js'
import { formMessages } from '@/validation/bid/Messages.js'
import { makeBid, editBid } from '@/services/orders/orderService'
import modalsMixin from '@/mixins/modalsMixin'
// import ClockOutline from 'mdi-vue/ClockOutline.vue'

import CurrencyUsd from 'mdi-vue/CurrencyUsd.vue';

export default {
    name: 'BidModal',
    components: {
        CurrencyUsd,
        FormDatePicker
        // ClockOutline
    },
    mixins: [validationMixin, formValidationMixin, filtersMixin, modalsMixin],
    validations: {
        ...validationRules
    },
    props: {
        due: { type: String },
        dueInAgo: { type: Boolean },
        orderid: { type: String },
        max: {
            type: [Number, String],
            default: 50
        },
        editDue: {
            type: String
        },
        editTotal: {
            type: String
        },
        bidId: {
            type: [Number, String]
        }
    },
    data() {
        return {
            loading: false,
            duedate: this.editDue || null,
            bidValue: this.editTotal || this.max
        }
    },

    computed: {
        total() {
            return this.bidValue
        },
        min() {
            return +this.max / 2
        },
        maxRate() {
            return this.max < this.bidValue
        }
    },
    created() {
        if (this.duedate === null) {
            this.duedate = moment().add(3, 'hours').toISOString()
        }
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        async submit() {
            if (this.bidId) {
                await this.editBid()
                return
            }
            await this.createBid()
        },
        async createBid() {
            this.loading = true
            await makeBid({
                orderid: this.orderid,
                date: this.duedate,
                total: +this.total
            })
                .then(() => {
                    eventBus.$emit('showSnackBar', 'The bid is created', 'success')
                    this.$emit('success')
                })
                .catch((e) => {
                    eventBus.$emit('showSnackBar', e, 'error')
                })
                .finally(() => {
                    this.loading = false
                    this.close()
                })
        },
        async editBid() {
            this.loading = true
            await editBid({
                id: this.bidId,
                date: this.duedate,
                total: +this.total
            })
                .then(() => {
                    eventBus.$emit('showSnackBar', 'The bid is edited', 'success')
                    this.$emit('success')
                })
                .catch((e) => {
                    eventBus.$emit('showSnackBar', e, 'error')
                })
                .finally(() => {
                    this.loading = false
                    this.close()
                })
        }
    }
}
</script>

<style lang="scss">
    .confirm-modal__alert{
        display: flex;
        color: #fff;
        background-color: #2196f3;
        border-color: #2196f3;
        padding: 16px;
        margin-top: 20px;
        border-radius: $input-border-radius;
        &-icon{
            margin-right: 20px;
        }
    }
    .bid {
        &-buttons {
            display: flex;
            justify-content: space-between;

            button {
                width: 48% !important;
            }
        }
        &-content {
            padding: 20px 20px 0;
        }
        &-desc {
            color: #1F2939;
        }
        &-datepicker {
            margin-top: 30px;

            .bid-desc {
                margin-bottom: 10px;
            }
        }
        &-range {
            &__fieldset {
                position: relative;
                margin: 10px 0 15px;
            }
            &__difference {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            &__input {
                position: absolute;
                left: 0;
                bottom: 2px;
                -webkit-appearance: none;
                width: 100%;
                height: 0;
                background-repeat: no-repeat;
                margin: 0;
                border: none;

                &::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    height: 16px;
                    width: 16px;
                    border-radius: 50%;
                    background: $main-color;
                    cursor: pointer;
                    box-shadow: 0 0 2px 0 #555;
                    transition: background .3s ease-in-out;
                    z-index: 9;
                }

                &:focus::-webkit-slider-thumb {
                    box-shadow: 0px 0px 0px 5px rgba(17, 97, 224, 0.2);
                }

                &::-webkit-slider-runnable-track  {
                    -webkit-appearance: none;
                    box-shadow: none;
                    border: none;
                    background: transparent;
                    z-index: 9;
                }
            }
            &__warning {
                color: #27ae60;
                font-size: 12px;
                line-height: 1;
                margin-bottom: 10px;
            }
            &__value {
                box-sizing: border-box;
                display: inline-block;
                width: 100%;
                height: 48px;
                user-select: text;
                font-family: $font-family-base;
                font-weight: 500;
                font-size: 18px;
                outline: none;
                border-radius: $input-border-radius;
                border: 1px solid #DDDDDD;
                padding-left: 45px;

                &--wrapper {
                    position: relative;
                    width: 100%;
                    max-height: 48px;
                    border-radius: $input-border-radius;
                    overflow: hidden;

                    .mdi-currency-usd {
                        position: absolute;
                        top: 12px;
                        left: 10px;
                        color: $main-color;
                    }
                }
            }

            &__progress {
                position: absolute;
                left: 0;
                bottom: 0;
                height: 3px;
                background: $main-color;
                z-index: 1;
            }
        }
        &-due {
            margin-top: 15px;
            display: flex;
            align-items: center;
            font-size: 20px;
            gap: 10px;
        }
    }
    .bid-deadline {
        display: flex;
        justify-content: space-between;
        padding: 10px 15px;
        background-color: rgba(17, 97, 224, 0.1);
        margin-bottom: 15px;
        border-radius: 5px;
    }
    .duedate--error {
        color: #d41c1c;
        font-weight: bold;
    }
</style>
