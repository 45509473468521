<template>
    <div class="order-bid">
        <div class="order-bid_data">
            <p class="order-bid_title">
                {{ order.bid.due_at | moment_from }}
                <b>
                    ${{ order.bid.sw_total }}
                </b>
            </p>
            <p class="order-bid_date">
                Due:
                <b>{{ order.bid.due_at | moment_short_text }}</b>
            </p>
        </div>
        <div class="order-bid_actions">
            <button @click="editBit()">
                <edit-icon size="18" />
            </button>
            <button @click="cancelBit()">
                <close-icon size="20" />
            </button>
        </div>

        <cancel-bid-modal
            ref="cancel-bid-modal"
            :bid-id="order.bid ? order.bid.id : 0"
            @success="updateOrder"
        />
        <bid-modal
            v-if="order.bid"
            ref="edit-bid-modal"
            :due-in-ago="isDueInAgo"
            :due="order.due_at"
            :edit-due="order.bid.due_at"
            :edit-total="order.bid.sw_total"
            :orderid="order.orderid"
            :max="order.sw_total"
            :bid-id="order.bid ? order.bid.id : 0"
            @success="updateOrder"
        />
    </div>
</template>

<script>
import moment from 'moment'

import closeIcon from 'mdi-vue/Close.vue'
import editIcon from 'mdi-vue/Pencil.vue'
import filtersMixin from '@/mixins/filtersMixin.js';

import CancelBidModal from '@/components/modals/CancelBidModal.vue';
import BidModal from '@/components/modals/BidModal.vue'

export default {
    components: {
        closeIcon,
        editIcon,
        CancelBidModal,
        BidModal
    },
    mixins: [
        filtersMixin
    ],
    props: {
        order: {
            type: Object,
            required: true
        }
    },
    computed: {
        isDueInAgo() {
            return moment(this.order.due_at) < moment()
        }
    },
    methods: {
        cancelBit() {
            this.$refs['cancel-bid-modal'].open()
        },
        editBit() {
            this.$refs['edit-bid-modal'].open()
        },
        updateOrder() {
            this.$emit('updateOrder')
        }
    }
}
</script>

<style lang="scss">
    .order-bid{
        display: flex;
        align-items: flex-start;
        border: 1px solid $main-color;
        padding: 10px;
        border-radius: 4px;
        &_title{
            color: $main-color;
            margin-bottom: 5px;
        }
        &_date{
            font-size: 12px;
        }
        &_actions{
            display: flex;
            margin-left: 10px;
            button {
                border: none;
                background: transparent;
                width: max-content;
                padding: 0;
                cursor: pointer;
                svg {
                    fill: $main-color;
                }
            }
        }
    }
</style>
