import {
    required,
    minLength,
    maxLength
} from 'vuelidate/lib/validators'

export const validationRules = {
    reason: {
        required,
        minLength: minLength(20),
        maxLength: maxLength(500)
    }
}
