<template>
    <footer
        v-if="showFooter"
        class="order_card_actions"
    >
        <!-- Finalize -->
        <custom-button
            v-if="allowFinalize"
            class="btn-base btn-main"
            @on-btn-click="completeTheOrder"
        >
            Finalize
        </custom-button>

        <!-- Drop -->
        <custom-button
            v-if="allowDrop"
            class="btn-base btn-border"
            @on-btn-click="drop"
        >
            <span class="card_footer_action-btn--clock" />
            Drop
        </custom-button>

        <!-- Take a project -->
        <!-- <custom-button
            v-if="allowTakeBid"
            class="btn-base btn-main"
            @on-btn-click="take"
        >
            Take a project
        </custom-button> -->

        <!-- Make a bid -->
        <custom-button
            v-if="allowMakeBid"
            class="btn-base btn-main"
            @on-btn-click="bid"
        >
            Make a bid
        </custom-button>

        <!-- MODALS -->
        <bid-modal
            v-if="allowMakeBid"
            ref="bid-modal"
            :due="data.due_at"
            :orderid="data.orderid"
            :max="data.sw_total"
            @success="updateOrder"
        />
        <drop-modal
            v-if="allowDrop"
            ref="drop-modal"
            :orderid="data.orderid"
            @success="updateOrder"
        />
        <take-modal
            v-if="allowTakeBid"
            ref="take-modal"
            :orderid="data.orderid"
            @success="updateOrder"
        />
    </footer>
</template>

<script>
import { mapGetters } from 'vuex'

import BidModal from '@/components/modals/BidModal';
import DropModal from '@/components/modals/DropModal';
import TakeModal from '@/components/modals/TakeModal';

import filtersMixin from '@/mixins/filtersMixin.js'
import { eventBus } from '@/helpers/event-bus';

export default {
    name: 'FooterActions',
    components: {
        BidModal,
        DropModal,
        TakeModal
    },
    mixins: [filtersMixin],
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapGetters('writer', {
            permissions: 'getterPermissions'
        }),
        hasBid() {
            return !!this.data.bid
        },
        allowMakeBid() {
            const statuses = [
                'FREE',
                'NEW',
                'INQUIRY'
            ]
            return !this.hasBid && statuses.includes(this.data.sw_status)
        },
        allowTakeBid() {
            const statuses = [
                'FREE',
                'NEW',
                'INQUIRY'
            ]
            return this.permissions.isTrusted && statuses.includes(this.data.sw_status) && this.allowMakeBid
        },
        allowDrop() {
            const statuses = [
                'PENDING',
                'REVISION',
                'COMPLETE'
            ]
            return statuses.includes(this.data.sw_status)
        },
        allowFinalize() {
            const statuses = [
                'PENDING',
                'REVISION'
            ]
            return statuses.includes(this.data.sw_status)
        },
        showFooter() {
            return this.allowMakeBid || this.allowTakeBid || this.allowDrop || this.allowFinalize
        }
    },
    methods: {
        completeTheOrder() {
            this.$router.push(`/cp/details/${this.data.orderid}/files`)
            setTimeout(() => {
                eventBus.$emit('finalizeFile')
            }, 1000)
        },
        drop() {
            this.$refs['drop-modal'].open()
        },
        bid() {
            this.$refs['bid-modal'].open()
        },
        cancelBit() {
            this.$refs['cancel-bid-modal'].open()
        },
        take() {
            this.$refs['take-modal'].open()
        },
        updateOrder() {
            this.$emit('updateOrder')
        }
    }
}
</script>

<style lang="scss">
    .order_card_actions{
        display: flex;
        .btn-base {
            margin-right: 20px;
        }
    }
</style>
