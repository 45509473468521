<template>
    <div
        class="order_status"
        :class="`order_status--${orderStatus}`"
    >
        {{ status }}
    </div>
</template>

<script>
export default {
    props: {
        status: {
            type: String,
            required: true
        }
    },
    computed: {
        orderStatus() {
            return this.status.toLowerCase()
        }
    }
}
</script>

<style lang="scss">
    .order_status {
        font-size: 14px;
        font-weight: 500;
        padding: 10px 15px;
        display: inline-block;
        border-radius: 4px;
        width: 100%;
        text-align: center;
        &--success {
            background: rgba(89, 199, 77, 0.1);
            color: #59c74d;
        }
        &--inquiry {
            background: rgba($main-color, 0.1);
            color: $main-color;
        }
        &--new {
            background: rgba($main-color, 0.1);
            color: $main-color;
        }
        &--free {
            background: rgba($main-color, 0.1);
            color: $main-color;
        }
        &--attention {
            background: rgba(255, 105, 12, 0.1);
            color: #ff690c;
        }
        &--revision {
            background: rgba(136, 86, 242, 0.1);
            color: #8856f2;
        }
        &--error {
            background: rgba(212, 28, 28, 0.1);
            color: #d41c1c;
        }
    }
</style>
