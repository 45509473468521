<template>
    <div
        v-if="dialog"
        class="confirm-modal bid"
        @click.self="close()"
    >
        <div class="confirm-modal__dialog">
            <header class="confirm-modal__header">
                <h2>Take order</h2>
                <span
                    class="confirm-modal__close"
                    @click="close()"
                />
            </header>
            <section class="confirm-modal__content bid-content">
                <h2> Are you sure that you want to take order? </h2>
            </section>
            <footer>
                <div class="confirm-modal__one-button-block bid-buttons">
                    <button
                        :disabled="loading"
                        class="btn-base btn-main"
                        @click.prevent="submit"
                    >
                        Confirm
                    </button>
                    <button
                        :disabled="loading"
                        class="btn-base btn-secondary"
                        @click="close()"
                    >
                        Cancel
                    </button>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
import { eventBus } from '@/helpers/event-bus'
import { takeOrder } from '@/services/orders/orderService.js'
import modalsMixin from '@/mixins/modalsMixin'

export default {
    name: 'TakeModal',
    mixins: [modalsMixin],
    props: {
        orderid: { type: String }
    },
    data() {
        return {
            loading: false
        }
    },
    methods: {
        async submit() {
            this.loading = true
            takeOrder(this.orderid).then(() => {
                eventBus.$emit('showSnackBar', 'The order has been taked.', 'success');
                this.$emit('success')
            })
                .catch((e) => {
                    eventBus.$emit('showSnackBar', e, 'error');
                })
                .finally(() => {
                    this.loading = false
                    this.close()
                })
        }
    }
}
</script>

<style lang="scss">
    .confirm-modal__alert{
        display: flex;
        color: #fff;
        background-color: #2196f3;
        border-color: #2196f3;
        padding: 16px;
        margin-top: 20px;
        border-radius: $input-border-radius;
        &-icon{
            margin-right: 20px;
        }
    }
    .bid {
        &-buttons {
            display: flex;
            justify-content: space-between;

            button {
                width: 170px;
            }
        }
        &-content {
            padding: 20px 20px 0;
        }
        &-desc {
            color: #1F2939;
        }
        &-datepicker {
            margin-top: 30px;

            .bid-desc {
                margin-bottom: 10px;
            }
        }
        &-range {
            &__fieldset {
                position: relative;
                margin: 10px 0 15px;
            }
            &__difference {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            &__input {
                position: absolute;
                left: 0;
                bottom: 2px;
                -webkit-appearance: none;
                width: 100%;
                height: 0;
                background-repeat: no-repeat;
                margin: 0;
                border: none;

                &::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    height: 16px;
                    width: 16px;
                    border-radius: 50%;
                    background: $main-color;
                    cursor: pointer;
                    box-shadow: 0 0 2px 0 #555;
                    transition: background .3s ease-in-out;
                    z-index: 9;
                }

                &:focus::-webkit-slider-thumb {
                    box-shadow: 0px 0px 0px 5px rgba(17, 97, 224, 0.2);
                }

                &::-webkit-slider-runnable-track  {
                    -webkit-appearance: none;
                    box-shadow: none;
                    border: none;
                    background: transparent;
                    z-index: 9;
                }
            }
            &__value {
                box-sizing: border-box;
                display: inline-block;
                width: 100%;
                height: 48px;
                user-select: text;
                font-family: $font-family-base;
                font-weight: 500;
                font-size: 18px;
                outline: none;
                border-radius: $input-border-radius;
                border: 1px solid #DDDDDD;
                padding-left: 45px;

                &--wrapper {
                    position: relative;
                    width: 100%;
                    max-height: 48px;
                    border-radius: $input-border-radius;
                    overflow: hidden;

                    .mdi-currency-usd {
                        position: absolute;
                        top: 12px;
                        left: 10px;
                        color: $main-color;
                    }
                }
            }

            &__progress {
                position: absolute;
                left: 0;
                bottom: 0;
                height: 3px;
                background: $main-color;
                z-index: 1;
            }
        }
    }
</style>
