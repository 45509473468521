/* eslint-disable no-return-await */
import Api from '@/helpers/api/index.js'

async function getByParams(query, cache) {
    return await Api.get('/api/order/list-by-params', query, { dialog: true }, cache).then((resp) => resp.data)
}

async function getByPreset(query, cache) {
    return await Api.get('/api/order/list-by-preset', query, { dialog: true }, cache).then((resp) => resp.data)
}

export const getOrders = async (searchObject, cache = false) => {
    const query = { ...searchObject }
    if (!query.preset) { delete query.preset }
    if (query.getType) { delete query.getType }
    if (!query.search_for) {
        delete query.search_by
        delete query.search_for
    }
    if (!query.from || !query.to) {
        delete query.from
        delete query.to
    }
    if (query.preset) {
        return await getByPreset(query, cache)
    }
    return await getByParams(query, cache)
}

export const fetchOrder = async (id) => await Api.get('/api/order/fetch', { id }, { dialog: true }, false).then((resp) => resp.data)

export const dropOrder = async (orderid, reason) => await Api.put('/api/order/drop', {
    orderid,
    reason
})

export const takeOrder = async (orderid) => await Api.put('/api/order/take', { orderid })

export const makeBid = async (data) => await Api.post('/api/order-bid/create', data)
export const editBid = async (data) => await Api.put('/api/order-bid/update', data)

export const getStatusesWithCounter = async () => {
    const presets = await Api.get('/api/order/list-presets', { }, { dialog: true }, false).then((resp) => resp.data)
    const result = presets.map((item) => ({
        value: item.value,
        title: item.title,
        count: item.counters?.count
    }))
    return result
}
