<template>
    <div class="order-card">
        <div
            v-if="showIndex"
            class="order-card_index"
        >
            {{ index + 1 }}
        </div>
        <div class="order-card_data">
            <div class="order-card_row">
                <div class="order-card_date_status">
                    <p class="order-card_created_at">
                        Created: <b>{{ order.created_at | moment_from }} ago</b>
                    </p>
                    <p
                        class="order-card_due_date"
                        :class="{'order-card_due_date--error': isDueInAgo}"
                    >
                        {{ isDueInAgo ? 'Overdue' : 'Due' }}:
                        <b>{{ order.due_at | moment_from }}</b>
                        {{ isDueInAgo ? 'ago' : '' }}
                    </p>

                    <div class="order-card_status">
                        <OrderCardStatus :status="order.sw_status" />
                    </div>
                </div>
                <div class="order-card_info">
                    <div class="order-card_row order-card_row--sb order-card_row--aic">
                        <div class="order-card_info__data">
                            <p
                                v-if="order.long_names.project_type"
                                class="order-card_info_type"
                            >
                                {{ order.long_names.project_type }}
                            </p>
                            <router-link
                                class="order-card_info_title"
                                :class="{'order-card_info_title--bold': !order.long_names.project_type}"
                                :to="{name: 'details', params: {id: order.orderid} }"
                            >
                                {{ getOrderTitle }}
                            </router-link>
                        </div>
                        <div class="order-card_info__additional">
                            <div class="order-card_row order-card_row--aic">
                                <div
                                    v-if="order.counters.other_bids_count > 0"
                                    class="order-card_bids"
                                >
                                    Other writers' bids:
                                    <span
                                        class="order-card_bids__counter"
                                        :class="{'order-card_bids__counter--disable': !order.counters.other_bids_count}"
                                        @click="showBidsToggle"
                                    >
                                        {{ order.other_bids.length }}
                                    </span>
                                    <OrderCardBidList
                                        v-if="showBids"
                                        :bids="order.other_bids"
                                        @closeBidsList="closeBidsList"
                                    />
                                </div>
                                <div class="order-card_bell">
                                    <OrderCardBell :data="order" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="order-card_row order-card_row--aic order-card_row--mt20 order-card_row--xs-column">
                        <div class="">
                            <OrderCardBid
                                v-if="order.bid"
                                :order="order"
                                @updateOrder="updateOrder"
                            />
                        </div>
                        <div class="order-card_right">
                            <router-link
                                v-if="!openedCard"
                                :to="{name: 'details', params: {id: order.orderid} }"
                                class="order-card_link"
                            >
                                View details
                            </router-link>
                            <div class="order-card_slide__wrapper">
                                <button
                                    v-if="!openedCard"
                                    class="order-card_slide"
                                    @click="isOpen = !isOpen"
                                >
                                    <ChevronUp v-if="isOpen" />
                                    <ChevronDown v-else />
                                </button>
                                <span
                                    class="order-card_slide__label"
                                    @click="isOpen = !isOpen"
                                >
                                    Show more
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <transition name="slide">
                <div
                    v-if="isOpen"
                    class="order-card_slide-row"
                >
                    <div class="order-card_row order-card_row--details">
                        <div class="order-card_row order-card_row--aic">
                            <div
                                class="order-card__detail order-card__detail--w100"
                            >
                                <div class="order-card__detail-title">
                                    Order ID
                                </div>
                                <div class="order-card_row order-card_row--aic">
                                    <img
                                        src="@/assets/img/svg/order-id.svg"
                                        alt="Order id"
                                        class="order-card__detail-img"
                                    >
                                    <div
                                        class="detail-value tooltip-zoom"
                                        style="cursor: pointer"
                                        data-tooltip-attribute="Copy to clipboard"
                                        @click="copyOrderId(order.orderid)"
                                    >
                                        {{ order.orderid }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="order-card_row order-card_row--aic">
                            <div
                                v-for="item in projectCount"
                                :key="item.title"
                                class="order-card__detail"
                                :class="{'grayscale': !item.isActive}"
                            >
                                <div class="order-card__detail-title">
                                    {{ item.title }}
                                </div>
                                <div class="order-card_row order-card_row--aic">
                                    <img
                                        :src="require(`@/assets/img/svg/${item.image}`)"
                                        :alt="item.title"
                                        class="order-card__detail-img"
                                    >
                                    <div class="order-card__detail-counter">
                                        {{ item.count || 0 }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="order-card_row order-card_row--details">
                        <div class="order-card_row order-card_row--aic order-card_row--w50">
                            <router-link
                                v-for="item in projectDetailsCount"
                                :key="item.title"
                                class="order-card__detail"
                                :class="{'grayscale': !item.isActive}"
                                :to="{name: item.to, params: {id: order.orderid} }"
                            >
                                <div class="order-card__detail-title">
                                    {{ item.title }}
                                </div>
                                <div class="order-card_row order-card_row--aic">
                                    <img
                                        :src="require(`@/assets/img/svg/${item.image}`)"
                                        :alt="item.title"
                                        class="order-card__detail-img"
                                    >
                                    <div class="order-card__detail-counter">
                                        {{ item.count || 0 }}
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                    <OrderCardActions
                        :data="order"
                        @updateOrder="updateOrder"
                    />
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import filtersMixin from '@/mixins/filtersMixin.js';
import { eventBus } from '@/helpers/event-bus'
import ChevronDown from 'mdi-vue/ChevronDown'
import ChevronUp from 'mdi-vue/ChevronUp'
import moment from 'moment'

import OrderCardStatus from './OrderCardStatus.vue'
import OrderCardBell from './OrderCardBell.vue'
import OrderCardBid from './OrderCardBid.vue'
import OrderCardBidList from './OrderCardBidList.vue'
import OrderCardActions from './OrderCardActions.vue'

export default {
    components: {
        OrderCardStatus,
        OrderCardBell,
        OrderCardBid,
        OrderCardBidList,
        OrderCardActions,
        ChevronDown,
        ChevronUp
    },
    mixins: [
        filtersMixin
    ],
    props: {
        order: {
            type: Object,
            required: true
        },
        showIndex: {
            type: Boolean,
            default: true
        },
        index: {
            type: Number,
            required: false
        },
        openedCard: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isOpen: false,
            showBids: false
        }
    },
    computed: {
        getOrderTitle() {
            const showDots = this.order.title.length > 40
            return `«${this.order.title.slice(0, 40)}${showDots ? '...' : ''}»`
        },
        projectCount() {
            return [
                {
                    title: 'Pages',
                    count: this.order.pages,
                    isActive: this.order.pages > 0,
                    image: 'order-page.svg'
                },
                {
                    title: 'Slides',
                    count: this.order.slides,
                    isActive: this.order.slides > 0,
                    image: 'order-slides.svg'
                },
                {
                    title: 'Charts',
                    count: this.order.charts,
                    isActive: this.order.charts > 0,
                    image: 'order-charts.svg'
                },
                {
                    title: 'Problems',
                    count: this.order.problems,
                    isActive: this.order.problems > 0,
                    image: 'problem.svg'
                }
            ]
        },
        projectDetailsCount() {
            return [
                {
                    title: 'Main files',
                    count: this.order.counters.files_main_count,
                    isActive: this.order.counters.files_main_count > 0,
                    image: 'order-page.svg',
                    to: 'order_files'
                },
                {
                    title: 'Supporting docs',
                    count: this.order.counters.files_add_count,
                    isActive: this.order.counters.files_add_count > 0,
                    image: 'order-slides.svg',
                    to: 'order_additional_files'
                },
                {
                    title: 'Tickets',
                    count: this.order.counters.tickets_count,
                    isActive: this.order.counters.tickets_count > 0,
                    image: 'order-ticket.svg',
                    to: 'order_support'
                },
                {
                    title: 'Messages',
                    count: this.order.counters.messages_count,
                    isActive: this.order.counters.messages_count > 0,
                    image: 'order-message.svg',
                    to: 'order_messages'
                }
            ]
        },
        isDueInAgo() {
            return moment(this.order.due_at) < moment()
        }
    },
    created() {
        this.isOpen = this.openedCard
    },
    methods: {
        copyOrderId() {
            navigator.clipboard.writeText(this.order.orderid)
            eventBus.$emit('showSnackBar', 'Copying to clipboard was successful!', 'success');
        },
        showBidsToggle() {
            if (this.order.other_bids.length === 0) return
            this.showBids = true
        },
        closeBidsList() {
            this.showBids = false
        },
        updateOrder() {
            this.$emit('updateOrder', this.order.orderid)
        }
    }
}
</script>

<style lang="scss">
    .order-card{
        display: flex;
        margin-bottom: 20px;
        border: 1px solid #e7ecf4;
        border-radius: 4px;
        position: relative;
        box-shadow: 0px 15px 45px rgba(0, 0, 0, 0.1);
        &_index{
            width: 40px;
            background: #1F2939;
            color: #fff;
            font-weight: 400;
            text-align: center;
            padding-top: 20px;
            @media all and (max-width: 768px) {
                width: 64px;
                height: 64px;
                border-radius: 4px;
                line-height: 64px;
                padding-top: 0;
                position: absolute;
                left: 20px;
                top: 20px;
            }
        }
        &_data {
            width: 100%;
            padding: 20px;
            .order-card_row{
                &:last-child {
                    padding-bottom: 0;
                }
            }
        }
        &_row {
            width: 100%;
            display: flex;
            @media all and (max-width: 768px) {
                flex-wrap: wrap;
            }
            &--sb {
                justify-content: space-between;
            }
            &--aic {
                align-items: center;
            }
            &--details{
                border-top: 1px solid #e7ecf4;
                padding-top: 20px;
                padding-bottom: 20px;
                @media all and (max-width: 768px) {
                    flex-direction: column;
                    align-items: flex-start;
                    & > .order-card_row {
                        &:first-child {
                            margin-bottom: 20px;
                        }
                    }
                }
            }
            &--w50{
                width: 50%;
                margin-left: auto;
                @media all and (max-width: 768px) {
                    margin-left: 0;
                    width: 100%;
                }
            }
            &--mt20{
                margin-top: 20px;
            }
            &--xs-column {
                @media all and (max-width: 768px) {
                    flex-direction: column;
                    align-items: flex-start;
                    margin-top: 10px;
                }
            }
        }
        &_due_date{
            &--error{
                color: $error-color;
            }
        }
        &_created_at{
            margin-bottom: 10px;
        }
        &_status{
            margin-top: 20px;
            @media all and (max-width: 768px) {
                width: calc(100% + 80px);
                margin-left: -80px;
                margin-top: 30px;
            }
        }
        &_date_status{
            width: 25%;
            padding-right: 20px;
            padding-bottom: 20px;
            border-right: 1px solid #e7ecf4;
            @media all and (max-width: 768px) {
                width: 100%;
                padding-left: 80px;
                padding-top: 10px;
                border-right: none;
                padding-right: 0;
            }
        }
        &_info {
            width: 75%;
            padding-left: 20px;
            padding-bottom: 20px;
            @media all and (max-width: 768px) {
                width: 100%;
                padding-left: 0;
            }
            &_type {
                font-weight: 700;
                margin-bottom: 10px;
            }
            &_title {
                color: #000;
                position: relative;
                &:before{
                    content: '';
                    bottom: -4px;
                    left: 0;
                    position: absolute;
                    width: 100%;
                    height: 3px;
                    border-radius: 15px;
                    background-color: $main-color;
                    transition: 0.2s;
                    transform: scaleX(0);
                }
                &:hover:before {
                    transform: scaleX(1);
                }
                &--bold {
                    font-weight: 700;
                }
            }
            &__data {
                margin-bottom: 10px;
            }
            &__data, &__additional{
                @media all and (max-width: 768px) {
                    width: 100%;
                }
            }
        }
        &_right {
            margin-left: auto;
            display: flex;
            align-items: center;
            @media all and (max-width: 768px) {
                margin-left: 0;
                justify-content: center;
                width: 100%;
                margin-top: 20px;
                flex-wrap: wrap;
                .order-card_link{
                    width: 100%;
                    margin-bottom: 10px;
                    margin-right: 0;
                }
            }
        }
        &_link {
            background: #1F2939;
            color: #fff;
            padding: 6px 12px;
            height: 36px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            white-space: nowrap;
            margin-right: 10px;
            font-weight: 500;
            font-size: 14px;
            justify-content: center;
            transition: .3s all;
            &:hover {
                background: rgba(31,41,57,1);
            }
        }
        &__detail{
            margin-right: 20px;
            width: calc(25% - 20px);
            color: #000;
            &--w100{
                width: 100%;
            }
            &-title {
                margin-bottom: 10px;
            }
            &-img{
                margin-right: 5px;
                width: 20px;
                height: 20px;
            }
            &:last-child {
                margin-right: 0;
            }
        }
        &_slide {
            width: 36px;
            height: 36px;
            background: transparent;
            border-radius: 50%;
            border: none;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: .3s all;
            position: relative;
            &:hover {
                background: #e7ecf4;
            }
            svg {
                width: 24px;
                height: 24px;
            }
        }
        &_bids{
            //position: relative;
            display: flex;
            align-items: center;
            &__counter{
                margin-right: 20px;
                margin-left: 10px;
                font-weight: 700;
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background: $main-color;
                color: #fff;
                cursor: pointer;
                &--disable{
                    background: gray;
                }
            }
        }
    }
    .slide-enter-active {
        transition-duration: 0.3s;
        transition-timing-function: ease-in;
    }
    .slide-leave-active {
        transition-duration: 0.3s;
        transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    }
    .slide-enter-to, .slide-leave {
        max-height: 200px;
        overflow: hidden;
    }
    .slide-enter, .slide-leave-to {
        overflow: hidden;
        max-height: 0;
    }
    .order-card_slide__wrapper{
        display: flex;
        align-items: center;
        .order-card_slide__label {
            color: #111720;
            font-weight: 700;
            white-space: nowrap;
            display: none;
            @media all and (max-width: 768px) {
                display: block;
            }
        }
    }
</style>
