import {
    required
} from 'vuelidate/lib/validators'

import moment from 'moment'

export const validationRules = {
    duedate: {
        required,
        minimumTimeframe(value) {
            return moment(value) > moment().add(2, 'hours')
        }
    },
    bidValue: {
        required,
        isNumber(value) {
            return /^\d{1,}\.{0,}\d{0,2}$/.test(value)
        }
        // minimumRate(value) {
        //     if (!+value && value !== 0) { return true }
        //     return value >= this.max / 2
        // }
    }
}
